import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Location, NgIf } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { RouterOutlet, RouterLinkActive, RouterModule } from '@angular/router';
import { LoadingComponent } from './shared/loading/loading.component';
import { LoadingService } from './services/loading.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, RouterLinkActive, RouterModule, LoadingComponent, NgIf],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  showBackButton: boolean = true;
  showAdminButton: boolean = false;
  showExitButton: boolean = true;

  constructor(
    private location: Location,
    private router: Router,
    private loadingService: LoadingService, 
    private cdr: ChangeDetectorRef
  ) { }
  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event.url;
        this.showBackButton = !(
          url === '/' || 
          url === '/loja' || 
          url === '/loja/inicio' || 
          url.startsWith('/loja/status') || 
          url.startsWith('/loja/login')
        );
        this.showAdminButton = (
          url === '/loja/login'
        );
        this.showExitButton = (
          url.startsWith('/admin')
        );
      }
    });

    this.loadingService.show();
    this.cdr.detectChanges();
  }

  goBack(): void {
    this.location.back();
  }
  exit() {
    this.router.navigate(['/admin/login'])
    localStorage.removeItem('authToken');
  }

  ngAfterViewInit(): void {
    this.loadingService.hide();
    this.cdr.detectChanges();
  }

  admin() {
    this.router.navigate(['/admin/login']);
  }
}
