<app-loading></app-loading>
<div id="app-root">
  <div class="d-md-flex justify-content-md-end m-2">
    <span class="btn-action" *ngIf="showAdminButton" (click)="admin()"><img src="../assets/adm.png" alt=""></span>
    <span class="btn-actiom" *ngIf="showExitButton" (click)="exit()"><img src="../assets/exit.png" alt=""></span>
  </div>
  <header class="d-flex justify-content-center align-items-center mb-4" *ngIf="!showExitButton">
    <img src="../assets/juntos-urt.jpg" class="img-fluid" alt="Banner | Seja sócio torcedor 2025!">
  </header>
  <div class="container">
    <!-- <div class="d-flex justify-content-between">
      <button (click)="goBack()" *ngIf="showBackButton" class="btn btn-outline-primary  btn-sm mb-4">voltar</button>
      <button type="button" class="btn btn-outline-primary btn-sm mb-4" *ngIf="showBackButton" (click)="exit()">sair</button>
    </div> -->
    <router-outlet></router-outlet>
  </div>
  <footer class="container pt-5">
    <div class="row">
      <div class="col text-center">
        <a [routerLink]="['/']" routerLinkActive="router-link-active" rel="home">
          <img src="../assets/logo-urt.webp" width="50px" alt="URT Logo" class="img-fluid">
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <p>Patos de Minas – MG<br>(34) 3821-0620 / (34) 99661-3821<br>contato&#64;urt.com.br</p>
      </div>
    </div>
  </footer>
</div>